/* HomePage.css */
@import url('https://use.fontawesome.com/releases/v5.0.8/css/all.css');

.homepage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background-color: #f0f0f0;
    /* Add more styling as needed */
}

.intro {
    text-align: center;
}

.profile-image {
    max-width: 200px;
    border-radius: 50%;
    margin-top: 20px;
}

.contact {
    margin-top: 20px;
}

.social-icons {
    font-size: 24px;
}

.social-icons a {
    color: #333;
    margin: 0 10px;
    text-decoration: none;
}

/* Add more styles for headings, paragraphs, etc. */
