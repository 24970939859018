/* Layout.css */
.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
}

.header {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    font-size: 24px;
}

.content {
    flex-grow: 1;
    padding: 20px;
}

.footer {
    background-color: #333;
    color: #fff;
    padding: 10px 0;
    text-align: center;
    font-size: 16px;
}
