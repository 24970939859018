/* AboutMe.css */
.about-me {
    background-color: #f5f5f5;
    padding: 40px;
}

h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.job {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.job h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.job p {
    font-size: 14px;
    color: #888;
    margin-bottom: 10px;
}

ul {
    list-style-type: disc;
    margin-left: 20px;
}

.job ul li {
    font-size: 14px;
    margin-bottom: 5px;
}

.education {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.education h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.education p {
    font-size: 14px;
    color: #888;
    margin-bottom: 10px;
}

.education-info {
    display: flex;
    align-items: center;
}

.education-info,
.job-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px; /* Add margin between each entry */
}

.education-logo,
.company-logo {
    flex-shrink: 0;
}

.university-logo,
.company-logo-img {
    max-width: 50px;
    height: auto;
    border-radius: 50%;
    margin-right: 20px; /* Adjust the margin as needed */
}

.education-details,
.company-details {
    flex-grow: 1;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .about-me {
        padding: 20px;
    }

    .job, .education {
        margin: 10px 0;
    }
}
