/* NavigationMenu.css */
.navigation-menu {
    background-color: #333;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.menu-items {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
}

.menu-items li {
    margin: 0;
}

.menu-items li a {
    display: block;
    padding: 15px 20px;
    color: #fff;
    text-decoration: none;
    transition: background-color 0.2s;
}

.menu-items li a:hover {
    background-color: #555;
}

/* Optional: Add styles for an active link */
.menu-items li a.active {
    background-color: #555;
}

/* Optional: Style the navigation links as buttons */
.menu-items li a {
    border: none;
    cursor: pointer;
    background-color: transparent;
    outline: none;
}

/* Optional: Add a hover effect for navigation links */
.menu-items li a:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
}
